@charset "utf-8";

.m-category-radio{
    margin-bottom: -34px;
    position: relative;
    z-index: 10;
    opacity: 0 !important;
}

.appNav li.disabled{
    background:#e1e1e1;
    color: #b1b1b1;
}
.appNav li:nth-child(odd).disabled{
    background:#d9d9d9;
}

.subNav{
    overflow: hidden;
    margin-bottom: 20px;
}
.subNav li{
    float: left;
    width: 120px;
    margin-right: 15px;
}
.subNav li a{
    border: 1px solid #ccc;
    display: block;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
}
.subNav li.active a{
    border: 1px solid #4eacab;
    background: #4eacab;
    color: #fff;
}
.btn-primary.disabled{
    background-color: #4eacab;
    border-color: #4eacab;
}